<template>
  <div class="column">
    <div class="card">
      <div class="card-body has-padding-100">
        <no-results
          :title="`Error ${error}`"
          :message="messages[error]"
          :cta="{ label: 'Go back' }"
          icon="exclamation-circle"
          @click="$router.go(-1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    code: {
      type: Number,
      default: 404
    }
  },
  data() {
    return {
      messages: {
        403: "You do not have permission to access this content.",
        404: "The page or content you were looking for could not be found."
      }
    };
  },
  computed: {
    error() {
      return this.$route.params.code || this.code;
    }
  }
};
</script>
